import { render, staticRenderFns } from "./invoices.vue?vue&type=template&id=4641973c&"
import script from "./invoices.vue?vue&type=script&lang=js&"
export * from "./invoices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bahaaeddine/public_html/hmd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4641973c')) {
      api.createRecord('4641973c', component.options)
    } else {
      api.reload('4641973c', component.options)
    }
    module.hot.accept("./invoices.vue?vue&type=template&id=4641973c&", function () {
      api.rerender('4641973c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/router/views/monitoring_and_management/suppliers_invoices/invoices.vue"
export default component.exports