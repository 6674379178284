var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3"),
      _c("div", { staticClass: "row mb-1" }, [
        _c("div", { staticClass: "col-md-6 mb-2 mb-md-0" }),
        _c("div", { staticClass: "col-md-6" }, [
          _vm.$can("create_supp_inv")
            ? _c(
                "button",
                {
                  staticClass: "btn btn-primary float-right",
                  on: { click: _vm.showNewInvoiceModalClick }
                },
                [
                  _c("i", { staticClass: "fas fa-plus" }),
                  _vm._v(" Nouvelle Facture ")
                ]
              )
            : _vm._e()
        ])
      ]),
      _c("hr", { staticClass: "mb-1" }),
      _c("h5", [
        _vm._v("Liste des factures pour le contrat N° : "),
        _c("b", [
          _c("span", { staticClass: "text-primary" }, [
            _vm._v(_vm._s(_vm.contract.ref))
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c(
            "div",
            {
              staticClass: "float-right",
              staticStyle: { "font-size": "18px" }
            },
            [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover",
                      value: "Activer le mode KANBAN",
                      expression: "`Activer le mode KANBAN`",
                      modifiers: { hover: true }
                    }
                  ],
                  class: _vm.showKanban ? "activeActionLink" : "",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showKanbanView($event)
                    }
                  }
                },
                [_c("i", { staticClass: "fab fa-trello" })]
              ),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "b-popover",
                      rawName: "v-b-popover.hover",
                      value: "Activer le mode TABLEAU",
                      expression: "`Activer le mode TABLEAU`",
                      modifiers: { hover: true }
                    }
                  ],
                  class: _vm.showTable ? "activeActionLink" : "",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showListView($event)
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-list ml-2" })]
              )
            ]
          )
        ])
      ]),
      _vm.showKanban
        ? _c("KanbanView", { attrs: { invoices: _vm.invoices } })
        : _vm._e(),
      _vm.showTable
        ? _c("ListView", { attrs: { invoices: _vm.invoices } })
        : _vm._e(),
      _c("new-invoice-modal", {
        attrs: { showModal: _vm.showNesInvoiceModal, contract: _vm.contract },
        on: { closeNewInvoiceModal: _vm.handleCloseNewInvoiceModalEvent }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }