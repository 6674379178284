var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { title: "Créer une facture", modalClass: "fullWidthModal" },
          on: { close: _vm.modalClosed },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "form",
            [
              _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                _c("i", { staticClass: "mdi mdi-alert-circle-outline mr-2" }),
                _vm._v(
                  "Le système prend en considération que les opérations validés. "
                ),
                _c("br"),
                _c("i", { staticClass: "mdi mdi-alert-circle-outline mr-2" }),
                _vm._v(
                  "Le contrôle des attachements se fait en utilisant le Montant "
                ),
                _c("b", [_vm._v("H.T")]),
                _vm._v(". "),
                _c("br"),
                _c("i", { staticClass: "mdi mdi-alert-circle-outline mr-2" }),
                _vm._v("Le contrat utilisé: "),
                _c("b", [_vm._v(_vm._s(_vm.contractRef))]),
                _vm._v(". ")
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("N° de facture *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newInvoiceForm.invoiceNo,
                          expression: "newInvoiceForm.invoiceNo"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.newInvoiceForm.invoiceNo.$error
                      },
                      attrs: {
                        id: "projectname",
                        type: "text",
                        placeholder: ""
                      },
                      domProps: { value: _vm.newInvoiceForm.invoiceNo },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.newInvoiceForm,
                            "invoiceNo",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.submitted && _vm.$v.newInvoiceForm.invoiceNo.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.newInvoiceForm.invoiceNo.required
                            ? _c("span", [_vm._v("Champ obligatoire.")])
                            : _vm._e(),
                          _c("br")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Date Facture *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newInvoiceForm.invDate,
                          expression: "newInvoiceForm.invDate"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.newInvoiceForm.invDate.$error
                      },
                      attrs: {
                        id: "projectname",
                        type: "date",
                        placeholder: ""
                      },
                      domProps: { value: _vm.newInvoiceForm.invDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.newInvoiceForm,
                            "invDate",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.submitted && _vm.$v.newInvoiceForm.invDate.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.newInvoiceForm.invDate.required
                            ? _c("span", [_vm._v("Champ obligatoire.")])
                            : _vm._e(),
                          _c("br")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Date de réception *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newInvoiceForm.receptionDate,
                          expression: "newInvoiceForm.receptionDate"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.newInvoiceForm.receptionDate.$error
                      },
                      attrs: {
                        id: "projectname",
                        type: "date",
                        placeholder: ""
                      },
                      domProps: { value: _vm.newInvoiceForm.receptionDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.newInvoiceForm,
                            "receptionDate",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.submitted && _vm.$v.newInvoiceForm.receptionDate.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.newInvoiceForm.receptionDate.required
                            ? _c("span", [_vm._v("Champ obligatoire.")])
                            : _vm._e(),
                          _c("br")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Total HT *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newInvoiceForm.untaxedAmnt,
                          expression: "newInvoiceForm.untaxedAmnt"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.newInvoiceForm.untaxedAmnt.$error
                      },
                      attrs: {
                        id: "projectname",
                        type: "text",
                        placeholder: ""
                      },
                      domProps: { value: _vm.newInvoiceForm.untaxedAmnt },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.newInvoiceForm,
                              "untaxedAmnt",
                              $event.target.value
                            )
                          },
                          _vm._refreshDisplayedAmnt
                        ]
                      }
                    }),
                    _vm.submitted && _vm.$v.newInvoiceForm.untaxedAmnt.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.newInvoiceForm.untaxedAmnt.required
                            ? _c("span", [_vm._v("Champ obligatoire.")])
                            : _vm._e(),
                          !_vm.$v.newInvoiceForm.untaxedAmnt.decimal
                            ? _c("span", [_vm._v("Champ invalide.")])
                            : _vm._e(),
                          _c("br")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("TAX *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newInvoiceForm.taxAmnt,
                          expression: "newInvoiceForm.taxAmnt"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted && _vm.$v.newInvoiceForm.taxAmnt.$error
                      },
                      attrs: {
                        id: "projectname",
                        type: "text",
                        placeholder: ""
                      },
                      domProps: { value: _vm.newInvoiceForm.taxAmnt },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.newInvoiceForm,
                            "taxAmnt",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.submitted && _vm.$v.newInvoiceForm.taxAmnt.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.newInvoiceForm.taxAmnt.required
                            ? _c("span", [_vm._v("Champ obligatoire.")])
                            : _vm._e(),
                          !_vm.$v.newInvoiceForm.taxAmnt.decimal
                            ? _c("span", [_vm._v("Champ invalide.")])
                            : _vm._e(),
                          _c("br")
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col-md-2" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("TTC *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newInvoiceForm.totalAmnt,
                          expression: "newInvoiceForm.totalAmnt"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid":
                          _vm.submitted &&
                          _vm.$v.newInvoiceForm.totalAmnt.$error
                      },
                      attrs: {
                        id: "projectname",
                        type: "text",
                        placeholder: ""
                      },
                      domProps: { value: _vm.newInvoiceForm.totalAmnt },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.newInvoiceForm,
                            "totalAmnt",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm.submitted && _vm.$v.newInvoiceForm.totalAmnt.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.newInvoiceForm.totalAmnt.required
                            ? _c("span", [_vm._v("Champ obligatoire.")])
                            : _vm._e(),
                          !_vm.$v.newInvoiceForm.totalAmnt.decimal
                            ? _c("span", [_vm._v("Champ invalide.")])
                            : _vm._e(),
                          _c("br")
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("div", {}, [
                    _c("div", {}, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(" Liste des attachements "),
                        _c("div", { staticClass: "float-right" }, [
                          _c("span", [
                            _vm._v(
                              "TTC Facture: " + _vm._s(_vm.amounts.invoiceAmnt)
                            )
                          ]),
                          _c("span", { staticClass: "ml-3" }, [
                            _vm._v(
                              "Total Attachements: " +
                                _vm._s(_vm.amounts.selectedAmt)
                            )
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "ml-3",
                              class: "text-" + _vm.amounts.diffClass
                            },
                            [
                              _vm._v(
                                "Différence: " + _vm._s(_vm.amounts.diffAmnt)
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table mb-0" }, [
                          _c("thead", { staticClass: "thead-light" }, [
                            _c("tr", [
                              _c("th", [_vm._v("ID")]),
                              _c("th", [_vm._v("N° Attachement")]),
                              _c("th", [_vm._v("Date")]),
                              _c("th", [_vm._v("Montant total")]),
                              _c("th", [_vm._v("Status")])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.attachments, function(
                              attachment,
                              index
                            ) {
                              return _c("tr", { key: attachment.id }, [
                                _c(
                                  "th",
                                  { attrs: { scope: "row" } },
                                  [
                                    _c("b-form-checkbox", {
                                      staticClass:
                                        "custom-checkbox-primary mb-3",
                                      attrs: {
                                        value: index,
                                        "unchecked-value": "not_accepted",
                                        checked: ""
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.handleSingleAttachmentClick(
                                            attachment
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          to: {
                                            name: "invoicing.display",
                                            params: { uid: attachment.uuid }
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(attachment.ref) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c("td", [
                                  _vm._v(_vm._s(attachment.dates.generatedAt))
                                ]),
                                _c("td", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(attachment.total_amnt.withCurrency)
                                    )
                                  ])
                                ]),
                                _c("td", [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        attachment.status.rendered
                                      )
                                    }
                                  })
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "submit" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.generateInvoice($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-save" }),
                    _vm._v(" Enregistrer ")
                  ]
                )
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }